<!-- Footer-Section Start-->
<section class="footer-section">
  <div class="container">
    <div class="footer-content">
      <div class="row g-3 justify-content-center mb-3">
        <div class="col-lg-3 col-md-12 d-flex align-items-center">
          <a href="index.html" class="footer-logo">
            <img class="img-fluid" src="assets/images/footer-logo.png" alt="logo">
          </a>
        </div>
        <div class="col-lg-2 col-md-3">
          <h5 class="mb-3" style="color: white !important;">Navigation</h5>
          <ul>
            <li><a routerLink="/home" class="btn-link">Home</a></li>
            <li><a (click)="goToAccommodation(undefined,1)" class="btn-link" routerLink="">Rooms</a></li>
            <li><a (click)="goToAccommodation(undefined,2)" class="btn-link" routerLink="">Huts</a></li>
            <li><a routerLink="/restaurants" class="btn-link">Restaurants</a></li>
            <li><a class="btn-link" routerLink="" (click)="loadLoginComponent()">Login</a></li>

          </ul>
        </div>
        <div class="col-lg-3 col-md-4">
          <h5 style="color: white !important;">Address</h5>
          <h6 style="color: white !important;">Diyar Resort</h6>
          <p class="text-white">Bypass Road, DhirKot,<br>
            Azad Jammu and Kashmir</p>
        </div>
        <div class="col-lg-3 col-md-5">
          <h5 class="mb-3" style="color: white !important;">Contact Us</h5>
          <div class="contact-info d-flex text-white mb-3">
            <div>
              <span>Mobile:</span>
            </div>
            <div class="fw-500">
              <span>+92 316 7224488</span>
            </div>
          </div>
          <div class="contact-info d-flex text-white mb-3">
            <div>
              <span>Email</span>
            </div>
            <div class="fw-500">
              <span>info&#64;diyarresorts.com</span>
            </div>
          </div>
          <div class="contact-info d-flex text-white mb-3">
            <div>
              <span>Whatsapp:</span>
            </div>
            <div class="fw-500">
              <span>+92 316 7224488</span>
            </div>
          </div>
          <div class="footer-icons d-flex gap-4">
            <div class="bg-icon">
              <a class="icon-link" href="https://www.facebook.com/diyarresorts?mibextid=LQQJ4d" target="_blank">
                <i class="fa fa-facebook"></i>
              </a>
            </div>
            <div class="bg-icon">
              <a class="icon-link" href="https://www.instagram.com/diyarresorts?igsh=b2M2b3JzczF2aDIz" target="_blank">
                <i class="fa fa-instagram"></i>
              </a>
            </div>
            <div class="bg-icon">
              <a class="icon-link"
                 href="https://www.tiktok.com/@diyar.resorts?_t=8pqd8r7jcu2&_r=1"
                 target="_blank">
                <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.9645 15.346V6.96585C15.5781 8.12408 17.5587 8.80894 19.695 8.80894V5.6072C18.4599 5.34556 17.3752 4.67734 16.5741 3.75854C15.2833 2.91778 14.354 1.57025 14.0591 0H11.0437L11.0381 16.5209C10.9714 18.3696 9.44695 19.8564 7.58318 19.8564C6.42592 19.8564 5.40778 19.2829 4.77354 18.4142C3.67195 17.8295 2.91531 16.677 2.91531 15.346C2.91531 13.4362 4.46754 11.8826 6.37584 11.8826C6.73191 11.8826 7.07685 11.9439 7.39955 12.0441V8.81452C3.30476 8.90361 0 12.2668 0 16.3929C0 18.3863 0.77334 20.2017 2.03626 21.5603C3.26582 22.4234 4.76797 22.9357 6.38141 22.9357C10.5596 22.9357 13.9645 19.5279 13.9645 15.346Z" fill="black" />
                </svg>
              </a>
            </div>
            <div class="bg-icon">
              <a class="icon-link"
                 href="https://www.google.com/search?q=diyar+resorts&rlz=1C1GCEU_enPK1043PK1043&oq=diyar+resorts&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyEwgBEC4YrwEYxwEYgAQYmAUYmgUyBwgCEAAYgAQyBwgDEAAYgAQyBwgEEAAYgAQyBggFEEUYPDIGCAYQRRg8MgYIBxBFGDzSAQgzNTgyajBqN6gCALACAA&sourceid=chrome&ie=UTF-8"
                 target="_blank">
                <i class="fa fa-google"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="copyright text-center">
      <small class="text-white">© Copyright Diyar Resorts – Designed by MTBC CareCloud </small>
    </div>
  </div>

</section>
<!-- Footer-Section End-->
