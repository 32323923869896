import { Injectable } from '@angular/core';
import { ToastrType } from '../Constants/const-var';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {


  constructor(private toastrService: ToastrService) { }
  /**
   * Check if a value is null or undefined.
   * @param value The value to check.
   * @returns True if the value is null or undefined, false otherwise.
   */
  isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }
  isNullOrEmpty(value: string | null | undefined): boolean {
    return value == null || value.trim() === '';
  }
  // Utility function to format the date as yyyy-MM-dd
  formatDate(date: any): string | null {
    const d = new Date(date);
    if (!isNaN(d.getTime())) {
      return d.toISOString().split('T')[0]; // Returns the date in yyyy-MM-dd format
    }
    return null; // Return null if the date is invalid
  }
  /**
   * Display a toast notification based on the type and message.
   * @param type The type of toastr notification.
   * @param message The message to display in the toastr.
   * @returns True if the toastr was shown, false otherwise.
   */
  toastr(type: ToastrType, message: string): boolean {
    this.toastrService.clear();
    if (this.isNullOrEmpty(message) || message.includes("User information is missing")) {
      console.log(message);
      return false;
    }

    switch (type) {
      case ToastrType.Success:
        this.toastrService.success(message);
        break;
      case ToastrType.Warning:
        this.toastrService.warning(message);
        break;
      case ToastrType.Info:
        this.toastrService.info(message);
        break;
      case ToastrType.Error:
        this.toastrService.error(message);
        break;
    }

    return true;
  }
}

