import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/Core/Services/Token/token-storage.service';
import { AuthenticationService } from 'src/app/Core/Services/authentiction/authentication.service';
import { ILogin } from 'src/app/Core/Services/authentiction/models/Login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  userProfile = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  })

  constructor(private authService: AuthenticationService,
    private router: Router,
    private tokenStorage: TokenStorageService, private modal: NgbModal) { }

  Login() {

    if (this.userProfile.valid) {
      const profile = this.userProfile.value;
      const userProfile: ILogin = {
        userName: profile.email,
        Password: profile.password,
        rememberMe: false
      };
      this.authService.Login(userProfile).subscribe((response: any) => {
        this.close();
        this.router.navigateByUrl('dashboard');
      })
    }
  }


  close() {
    this.modal.dismissAll();
  }

}
