import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/Modules/Auth/AuthModule/login/login.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  private activeModal: NgbActiveModal;
  private modalRef: NgbModalRef;
  modalOptions: NgbModalOptions;

  constructor(private router: Router, private modal: NgbModal) {
    this.modalOptions = {
      backdrop: 'static',
      size: 'md',
      fullscreen: 'lg',
      keyboard: false,
      centered: true
    };
  }
  ngOnInit(): void {

  }

  goToAccommodation(id?: string, type?: number): void {
    if (id) {
      this.router.navigate(['cat-view/', id]);
      console.log('Navigating with ID:', id);
    } else if (type) {
      this.router.navigate(['cat-view/', type]);
      console.log('Navigating with Type:', type);
    } else {
      console.log('No ID or Type provided');
    }
  }

  async loadLoginComponent() {
    this.modalRef = this.modal.open(LoginComponent, this.modalOptions)
  }
}
