<nav class="navbar navbar-light sticky-top position-fixed" style="top: 0px;"
     [ngClass]="{'bg-pastal-green nav-expanded': isMenuOpen,'active-navbar': isNavbarActive}">
  <div class="container d-block position-relative">
    <div class="d-flex justify-content-between align-items-center">
      <nav class="top-navbar">
        <div class="container-fluid">
          <h2 class="navbar-toggler" (click)="toggleMenu()" aria-controls="navbarToggleExternalContent"
              aria-expanded="false" aria-label="Toggle navigation">
            <ng-container *ngIf="isMenuOpen; else menuText">
              <!-- Conditional rendering for the menu button icon/text -->
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_581_890" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                  <rect width="24" height="24" fill="#1B3C25" />
                </mask>
                <g mask="url(#mask0_581_890)">
                  <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#1B3C25" />
                </g>
              </svg>
            </ng-container>
            <ng-template #menuText>Menu</ng-template>
          </h2>
        </div>
      </nav>
      <div class="mx-auto">
        <a href="#">
          <img class="img-fluid diyar-logo" src="assets/images/diyar-resort-logo.svg" alt="">
        </a>
      </div>
      <a class="btn btn-curve" (click)="confirmBooking()">Reserve</a>
    </div>

    <div [ngClass]="{'collapse show': isMenuOpen, 'collapse': !isMenuOpen}" id="navbarToggleExternalContent">
      <div class="p-3">
        <div class="home-nav d-flex justify-content-between py-2 py-lg-0">
          <div class="nav-item dropdown">
            <a class="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown"
               aria-expanded="false" id="dropdownMenuButton" aria-haspopup="true">
              Accommodation
            </a>
            <div class="dropdown-menu sub-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="goToAccommodation(undefined,1)">Rooms</a>
              <a class="dropdown-item" (click)="goToAccommodation(undefined,2)">Huts</a>
            </div>
          </div>
          <a routerLink="/restaurants" class="nav-item nav-link">Restaurants</a>
          <a routerLink="/adventures" class="nav-item nav-link">Activities</a>
          <a routerLink="/contact" class="nav-item nav-link">Contact Us</a>
        </div>
      </div>
    </div>
  </div>
</nav>
